import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Section, SectionWrapper, Text } from "../../core/commonExports"
import { Button } from "../../core/Button"

const FooterSection = ({
  handleGetQuoteClick,
  style={},
  mobileStyles={background: "#fff"},
  desktopStyles={background: "#F6F9FF"}
}) => {
  return (
    <Section mobileStyles={mobileStyles} desktopStyles={desktopStyles} style={style} className="position-relative">
      <SectionWrapper>
        <Container style={{width: '100%', position: 'relative', alignItems: 'center'}}>
          <HeadingContainer style={{marginLeft: '0', maxWidth: '426px'}}>
            <Text fontSize="42px" mfontSize="20px" lineHeight="62px" mlineHeight="36px" fontWeight="bold" color="#333333" style={{textAlign: "left", margin: "0", marginBottom: '40px'}}>
              Secure your stock, cash, and equipment against 15+ risks {" "}
              <span style={{color: "rgb(255, 147, 79)"}}>
                NOW!
              </span>
            </Text>
            <div className="on-desktop">
              <Button label="VIEW PRICES" type="flatdesign" wide onClick={handleGetQuoteClick} />
            </div>
          </HeadingContainer>
          <div style={{width: "min(593px, 100%)", height: "auto"}}>
            <StaticImage
              src="../../../assets/images/campaigns/glp9-footer-section-img.webp"
              alt="secure your business"
              loading='lazy'
              width={593}
              height={426}
            />
          </div>
          <div className="on-mobile">
            <Button label="VIEW PRICES" type="flatdesign" wide style={{margin: 'auto', marginTop: '2rem'}} onClick={handleGetQuoteClick} />
          </div>
        </Container>
      </SectionWrapper>
    </Section>
  )
}

const HeadingContainer = styled.div`
  margin-left: 150px;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin: 20px 0;
    flex-direction: ${({ reverse }) =>
      `${reverse ? "column-reverse" : "column"}`};
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export default FooterSection